<template>
    <base-layout>
        <div class="page-fwa-products container">

            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Fixed Wireless Products</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-table
                        class="products-table"

                        :cols="products.cols"
                        :rows="transformed_product_orders"

                        :sort-value="products.filter.sort"
                        :sort-options="products.sort"
                        @sort-change="onSortChange"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import baseLayout from '@/views/layouts/base-layout'
import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import formatDateNano from '@/helpers/format-date-nano'
import appPagination from '@/components/app-pagination'
import { mapGetters } from 'vuex'

export default {
    components: {
        appTable,
        appPagination,
        baseLayout,
        appLoader,
    },

    data() {
        return {
            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,

            products: {
                cols: [
                    { key: 'UUID',          title: 'Product Instance UUID', highlight: true,                },
                    { key: 'ProductType',   title: 'Product Type',          sort: { field: 'ProductType'  } },
                    { key: 'CreatedAtText', title: 'Created At'                                             },
                    { key: 'UpdatedAtText', title: 'Updated At'                                             },
                ],

                filter: {
                    word: '',
                    previous: '',
                    sort: {
                        field: 'ProductType',
                        order: 'asc'
                    },
                },

                sort: [
                    { field: 'ProductType', title: 'Product Type' },
                ],
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.obtainData(this.pagination.page)
        },

        obtainData(page) {

            /**
             * @todo
             * Add sorting params and listen sorting change event
             */

            this.loading = true

            this.$store.dispatch('fwa/GetFWAProductInstances', {
                SearchOptions: {
                    PageNumber: this.pagination.page,
                    PageSize: this.pagination.limit,
                }
            }).then(({ data }) => {
                this.pagination.total = data.PageInfo.TotalItemCount
                this.pagination.page = page

                this.loading = false
            }).catch(error => {
                console.log(error)
                this.loading = false
            })
        },

        onPageChange(page) {
            this.obtainData(page)
        },

        onSortChange(sort) {
            console.log(sort)
            this.products.filter.sort = sort
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),
        // ...mapGetters({
        //     'pageinfo': 'fwa/fwa_product_orders_pageinfo',
        // }),
        transformed_product_orders() {
            let resp = this.$store.getters['fwa/fwa_product_instances']

            resp = resp.map(thispo => ({
                UUID: thispo.UUID,
                ProductType: thispo.ProductType,
                CreatedAtText: formatDateNano(thispo.CreatedAtNanos, 'YYYY/MM/DD HH:ii:ss'),
                UpdatedAtText: formatDateNano(thispo.UpdatedAtNanos, 'YYYY/MM/DD HH:ii:ss'),
            }))

            return resp
        },
    },
}
</script>

<style lang="scss">
.page-fwa-products {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .products-table {
        @include table-cols-width((400px, 200px, 175px, 175px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-fwa-products {
        .products-table {
            @include table-cols-width((200px, 150px, 90px, 90px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fwa-products {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .products-table {
            @include table-cols-width-mobile((110px, 165px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>